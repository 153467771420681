import React, { useState, useEffect } from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import axios from 'axios'
import _ from 'lodash'

export const ErrorCodes = {

    recaptchaRefreshNeeded: -40001
}

export const verifyReCaptcha = (responseVal, path) => {

    return (process.env.NODE_ENV === "development") ? Promise.resolve({ data: { isError: false } }) : axios({
        url: `${path || ''}/verifyCaptcha`,
        method: 'POST',
        data: { response: responseVal },
        responseType: 'json'
    })
    .then(resp => {
        
        if (resp.data.isError) {

            return {
                isError: true,
                errorCode: ErrorCodes.recaptchaRefreshNeeded
            }

        } else {

            return resp.data
        }

    }, error => { return { isError: true } })
}

const useRecaptcha = (recaptchaClientKey, onRecaptchaResult, path) => {

    const [recaptcha, setRecaptcha] = useState(undefined)
    const [fetchingRecaptcha, setFetchingRecaptcha] = useState(false)
    const recaptchaRef = React.createRef()

    /* NOTE: There's probably a way to keep this from rerendering, but at this point it doesnt seem harmful */
    
    const recaptchaComponent = (

            <ReCAPTCHA
                sitekey={recaptchaClientKey}
                onChange={value => setRecaptcha(value)}
                size='invisible'
                ref={recaptchaRef}
            />
    )

    useEffect(() => {

        if (fetchingRecaptcha) {

            if (_.isUndefined(recaptcha)) {

                console.log("Refreshing recaptcha token ...")

                recaptchaRef.current.reset()
                recaptchaRef.current.execute()

            } else {

                console.log("Verifying recaptcha token ...")

                verifyReCaptcha(recaptcha, path)
                    .then(rcResp => {

                        console.log("Calling client code ...")
                        
                        /* Run client code letting them know the result */
                        
                        onRecaptchaResult(rcResp)

                        return rcResp
                    })
                    .then(rcResp => {
                        
                        /* We could look at the result to do something more, but for now, just reset to undefined to make sure the next call runs again */
                        
                        setRecaptcha(undefined)
                    })
                
                /* No matter what, do allow back in unless startRecaptchaTransaction is called again */
                
                setFetchingRecaptcha(false)
            }
        }

    }, [fetchingRecaptcha, recaptcha, recaptchaRef, onRecaptchaResult, path])

    const startRecaptchaTransaction = () => {

        setFetchingRecaptcha(true)
    }

    return [startRecaptchaTransaction, recaptchaComponent]
}

export default useRecaptcha