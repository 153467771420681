import React, { useState, useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import _ from 'lodash'

/* TODO: Make sure this is working as expected and the scope is not too broad */

const ShowHidePasswordContext = React.createContext([{}, () => { }])

export const ShowHidePasswordProvider = (props) => {

  const [showPassword, setShowPassword] = useState(false)

  return (
    <ShowHidePasswordContext.Provider value={[showPassword, setShowPassword]}>
      {props.children}
    </ShowHidePasswordContext.Provider>
  )
}

export const ShowHidePasswordTextField = (props) => {

    const [showPassword, setShowPassword] = useContext(ShowHidePasswordContext)

    const style = {
        position: 'absolute',
        top: '16px',
        right: '10px'
    }
    
    return (
        <div style={{ position: 'relative', width: '100%'}}>       
            <TextField
                variant="outlined"
                required
                fullWidth
                type={showPassword ? 'input' : 'password'}
                inputProps={{
                    maxLength: 12,
                    tabIndex: props.tabIndex
                }}
                {..._.omit(props, ['showIcon', 'tabIndex'])}
            />
            {props.showIcon && (
                showPassword ?
                    <VisibilityIcon onClick={evt => setShowPassword(false)} style={style} />     
                    :
                    <VisibilityOffIcon onClick={evt => setShowPassword(true)} style={style} />      
            )}     
        </div>  
    )
}