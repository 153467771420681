import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import MaterialLink from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { ShowHidePasswordTextField, ShowHidePasswordProvider } from './ShowHidePassword'
import _ from 'lodash'
import GooglePhoneUtils from 'google-libphonenumber'
import { createUser } from '../services/Backend'
import Utils from '../utils/Utils'
import Constants, { RegistrationSteps } from '../Constants'
import ProgressButton from './ProgressButton'
import InfoStep from './InfoStep'
import useRecaptcha from '../hooks/Recaptcha'
import InfoIcon from '@material-ui/icons/Lock'
import MaskedInput from 'react-text-mask'
import Moment from 'moment'

const PhoneInput = ({ display, onChange, disable, require, tIndex }) => {

    return (
        <Grid item xs={12}>
            <TextField
                variant="outlined"
                required={require}
                fullWidth
                value={display}    
                onChange={onChange}  
                disabled={disable}
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete="phone"
                inputProps={{
                    maxLength: 14,
                    tabIndex: tIndex
                }}
            />
        </Grid>
    )
}

const SSNTextMaskCustom = (props) => {

    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            showMask
        />
    );
}

const Roles = {

    member: 'member',
    broker: 'broker',
    hrRep: 'hr-rep'
}

const Register = ({ history, location, inRole }) => {

    const phoneUtils = GooglePhoneUtils.PhoneNumberUtil.getInstance()

    const theme = useTheme()

    const [role, setRole] = useState(Roles.hrRep)
    const [registrationStep, setRegistrationStep] = useState(RegistrationSteps.collectUserInfo)
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        groupName: '',
        agentId: 'REFER-TO-TOP-LEVEL',
        phone: '',
        email: '',
        password: '',
        confirm: '',
        ssn: '',
        dob: ''
    })
    const [creatingUser, setCreatingUser] = useState(false)
    const [errorContent, setErrorContent] = useState({
        title: undefined,
        description: undefined
    })
    // const [showPasswordRules, setShowPasswordRules] = useState(true)
    const [showPasswordRules] = useState(true) // just getting rid of warning until used

    useEffect(() => {

        const params = Utils.getQueryParams()
        
        const step = params['step'] || RegistrationSteps.collectUserInfo
        const role = params['role'] || inRole

        setRegistrationStep(Number(step))
        setRole(role)
        
    }, [inRole])

    /* These are here because there 3 types of Broker paths instead of 1 and then roles are added */
    
    const isMember = _.toLower(location.pathname) === _.toLower('/registerMember')
    const isPortalBroker = _.toLower(location.pathname) === _.toLower('/registerBroker')
    const isFloridaHRRep = _.toLower(location.pathname) === _.toLower('/registerFlorida')
    const isOnlineRatingEnabled = _.toLower(location.pathname) === _.toLower('/registerOnlineRatingBroker')

    const handleRegister = (rcResp) => {

        const extras = _.merge(
            {
                firstName: userData.firstName,
                lastName: userData.lastName,
                phone: userData.phone,
            }, role === Roles.hrRep ? { groupName: userData.groupName } : (isOnlineRatingEnabled || isPortalBroker) ? { agentId: userData.agentId } : {})
        
        const createRoles = () => {

            if (role === Roles.broker) {

                if (isOnlineRatingEnabled) {

                    return { [Roles.broker]: { ...extras }, "rating": "" }
                    
                } else {

                    return { [Roles.broker]: { ...extras } }
                }
            
            } else if (role === Roles.member) {

                return {
                    [Roles.member]: {
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        phone: userData.phone,
                        ssn: Number(_.split(userData.ssn, '-').join('')),
                        dob: Moment(userData.dob).format("MMDDYYYY")
                    }
                }

            } else {

                if (isFloridaHRRep) {

                    return { [Roles.hrRep]: { ...extras }, "florida": "" }

                } else {

                    return { [Roles.hrRep]: { ...extras } }
                }
            }
        }

        return (rcResp.isError ? Promise.resolve(rcResp) : createUser(userData.email, userData.password, createRoles()))
            .then(resp => {

                setUserData({ ..._.mapValues(userData, value => '') })

                if (resp.isError) {

                    setErrorContent({
                        title: resp.isDupUser ? 'User Already Exists' : resp.isMemberNotFound ? 'Member Not Found' : 'Unexpected Error',
                        description: resp.isDupUser ? `The email provided is not available. You can try another email by clicking the Back button below.` :
                            resp.isMemberNotFound ? `Member information enter was not found in the system. Pleaes double check the information and try again. If you need help, please contact support.` : `An unexpected error has occured while trying to register. Please try again later.`
                    })

                    setRegistrationStep(RegistrationSteps.error)
                
                } else {
                
                    setRegistrationStep(RegistrationSteps.verificationEmailSent)
                }

                setCreatingUser(false)
            })
    }

    const [startRecaptchaTransaction, recaptchaComponent] = useRecaptcha(Constants.recaptchaClientKey, handleRegister)

    const allowOnlyAlphaOnKeyPress = (evt) => {

        const allowed = /^[/\ta-zA-Z/\s-]+$/
        const allowedKeys = [8,37,39,45,189]

        const code = !evt.charCode ? evt.which : evt.charCode
        const key = String.fromCharCode(code)

        if (!allowed.test(key) && _.findIndex(allowedKeys, v => v === code) === -1) {

            evt.preventDefault()
            return false
        }
    }

    /*
    const allowNumericOnKeyPress = (evt) => {

        const allowed = /^[/\t0-9]+$/
        const allowedKeys = [8,37,39]

        const code = !evt.charCode ? evt.which : evt.charCode
        const key = String.fromCharCode(code)

        if (!allowed.test(key) && _.findIndex(allowedKeys, v => v === code) === -1) {

            evt.preventDefault()
            return false
        }
    }
    */

    const handleSetName = (key, forceCamelCase = true) => (evt) => {

        const cleaned = evt.target.value.replace(/ +(?= )/g, '')

        /* This is looking for special character thats hard to filter in keypress */
        
        const cleanedSections = _.filter(_.map(cleaned.split(' '),
            v => v.trim().length === 0 ? v : forceCamelCase ? _.capitalize(v) : v), section => section !== '.')
        
        const cased = _.join(cleanedSections, ' ')

        setUserData({ ...userData, [key]: cased})
    }

    const onBlurTrim = (key) => (evt) => {

        setUserData({ ...userData, [key]: evt.target.value.trim()})
    }

    const handlePhoneChange = (evt) => {

        const newValue = evt.target.value
        const lastEntered = _.last(newValue.split(''))

        if (_.isUndefined(lastEntered) || (lastEntered === '1' && evt.target.value.trim().length === 1)) {
            
            setUserData({ ...userData, phone: undefined })
            return
        }

        if (isNaN(lastEntered) || parseInt(lastEntered) < 0) {

            evt.target.value = newValue.substring(0, newValue.length - 1)

        } else {

            const cleaned = newValue.replace(/-/g, '')

            if (cleaned.length > 3) {

                const newState = phoneUtils.parseAndKeepRawInput(cleaned, 'US').getRawInput()

                setUserData({ ...userData, phone: newState })
                
            } else {

                setUserData({ ...userData, phone: newValue })
            }
        }
    }

    const isValidPhone = (phone) => {
        
        return !_.isUndefined(phone) && phone.trim().length > 2 ? phoneUtils.isValidNumber(phoneUtils.parseAndKeepRawInput(phone, 'US')) : false
    }

    const formatPhone = (phone) =>
        
        _.isUndefined(phone) ? '' : phone.length > 2 ? phoneUtils.formatInOriginalFormat(phoneUtils.parseAndKeepRawInput(phone, 'US'), 'US') : phone

    const isValidName = (name) => name.trim().length >= 2

    const isInputCompleteByRole = (omitPasswordConfirm) => {

        const remove = omitPasswordConfirm ? ['confirm']: []

        switch(role) {
            
            case Roles.broker:

                return Utils.isCompleteData(_.omit(userData,
                    _.concat(remove, (isOnlineRatingEnabled || isPortalBroker) ? ['groupName', 'ssn', 'dob'] : ['groupName', 'agentId', 'ssn', 'dob'])))
            
            case Roles.member:

                return Utils.isCompleteData(_.omit(userData, _.concat(remove, _.concat(['groupName', 'agentId'], _.isEmpty(userData.phone) ? ['phone'] : []))))
            
            default:

                return Utils.isCompleteData(_.omit(userData, _.concat(remove, ['agentId', 'ssn', 'dob'])))
        }
    }

    const showPasswordError = () => isInputCompleteByRole(true) && !Utils.isValidPassword(userData.password,
        Constants.allowedSpecialChars, Constants.validPasswordRegex)

    const showConfirmError = () => userData.password.length > 0 && (userData.password !== userData.confirm || !Utils.isValidPassword(userData.confirm,
        Constants.allowedSpecialChars, Constants.validPasswordRegex))
    
    const validateSSN = (inSSN) => {
        
        return inSSN.indexOf("_") === -1 && inSSN.length === 11
    }

    const validateDOB = (inDOB) => {

        return inDOB.length === 10 && Moment().subtract(80, 'years').isBefore(Moment(inDOB, 'YYYY-MM-DD'))
    }

    const inputCompleteWhen = () => {

        return isInputCompleteByRole(false)
            && isValidName(userData.firstName)
            && isValidName(userData.lastName)
            && ((isMember && _.isEmpty(userData.phone)) ? true : isValidPhone(userData.phone))
            && (isMember ? validateSSN(userData.ssn) : true)
            && (isMember ? validateDOB(userData.dob) : true)
            && Utils.isValidEmail(userData.email)
            && Utils.isValidPassword(userData.password, Constants.allowedSpecialChars, Constants.validPasswordRegex)
            && userData.password === userData.confirm
    }

    const handleRegisterStart = (evt) => {

        startRecaptchaTransaction(true)
        setCreatingUser(true)
    }

    const handleEnterToSubmit = (evt) => {

        if (evt.key === "Enter" && inputCompleteWhen()) {

            handleRegisterStart()
        }
    }

    const toHome = (evt) => history.replace('/internal-signin')

    const toHomeOnComplete = (evt) => history.replace('/signin')

    if (registrationStep === RegistrationSteps.onComplete) {

        console.log('Starting timer to send user in ...')

        Utils.timedRedirect('/signin', history, 10)
    }

    return (
        <>
            <Typography component="h1" variant="h5">
                {_.isUndefined(role) || role === Roles.hrRep ? '' : `${_.capitalize(role)} `}Registration
            </Typography>
            {isOnlineRatingEnabled && (
                <Typography variant="body1">
                    Online Rating Enabled
                </Typography>
            )}
            {isFloridaHRRep && (
                <Typography variant="body1">
                    Florida
                </Typography>
            )}
            {registrationStep === RegistrationSteps.collectUserInfo && (
                <ShowHidePasswordProvider>
                    <Grid container spacing={2} style={{ marginTop: theme.spacing(2) }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                value={userData.firstName}    
                                onChange={handleSetName('firstName')}  
                                onKeyDown={allowOnlyAlphaOnKeyPress}   
                                onBlur={onBlurTrim('firstName')}    
                                disabled={creatingUser}
                                id="given-name"
                                label="First Name"
                                name="given-name"
                                autoComplete="given-name"
                                autoFocus
                                inputProps={{
                                    tabIndex: 1
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                value={userData.lastName}    
                                onChange={handleSetName('lastName')} 
                                onBlur={onBlurTrim('lastName')}    
                                onKeyDown={allowOnlyAlphaOnKeyPress} 
                                disabled={creatingUser}
                                id="family-name"
                                label="Last Name"
                                name="family-name"
                                autoComplete="family-name"
                                inputProps={{
                                    tabIndex: 2
                                }}
                            />
                        </Grid>
                        {!isMember &&
                            <PhoneInput
                                display={formatPhone(userData.phone)}
                                onChange={handlePhoneChange}
                                disable={creatingUser}
                                require={true}
                                tIndex={3}
                            />
                        }
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                value={userData.email}    
                                onChange={evt => setUserData({ ...userData, email: evt.target.value.trim() })} 
                                disabled={creatingUser}
                                id="email"
                                label="Email Address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                inputProps={{
                                    tabIndex: 4
                                }}
                            />
                        </Grid>
                        {role === Roles.hrRep &&
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    value={userData.groupName}
                                    onChange={handleSetName('groupName', false)}
                                    onBlur={onBlurTrim('groupName')}
                                    onKeyDown={allowOnlyAlphaOnKeyPress}
                                    disabled={creatingUser}
                                    id="groupName"
                                    label="Group Name"
                                    name="groupName"
                                    inputProps={{
                                        tabIndex: 5
                                    }}
                                />
                            </Grid>
                        }
                        {isMember &&
                            <>
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    error={!_.isEmpty(userData.ssn) && !validateSSN(userData.ssn)}
                                    disabled={creatingUser}
                                    id="ssn"
                                    label="SSN"
                                    name="ssn"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        tabIndex: 5
                                    }}
                                    InputProps={{
                                        inputComponent: SSNTextMaskCustom,
                                        value: userData.ssn,
                                        onChange: e => { setUserData({ ...userData, ssn: e.target.value }) }
                                      }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    error={!_.isEmpty(userData.dob) && !validateDOB(userData.dob)}
                                    value={userData.dob}
                                    onChange={e => setUserData({ ...userData, dob: e.target.value})}
                                    showMask={false}
                                    disabled={creatingUser}
                                    id="dob"
                                    label="Date of Birth"
                                    name="dob"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        tabIndex: 6
                                    }}
                                />
                            </Grid>
                            <PhoneInput
                                display={formatPhone(userData.phone)}
                                onChange={handlePhoneChange}
                                disable={creatingUser}
                                require={false}
                                tIndex={7}
                            />
                            </>
                        }
                        <Grid item xs={12}>
                            <ShowHidePasswordTextField
                                id="password"
                                name="password"
                                label="Password"
                                error={showPasswordError()}
                                value={userData.password}    
                                onChange={evt => setUserData({ ...userData, password: evt.target.value })} 
                                disabled={creatingUser}
                                showIcon
                                inputProps={{
                                    tabIndex: isMember ? 8 : 6
                                }}
                            />     
                        </Grid>   
                        <Grid item xs={12}>
                            <ShowHidePasswordTextField
                                id="confirm"
                                name="confirm"
                                label="Confirm Password"
                                error={showConfirmError()}
                                value={userData.confirm}
                                onChange={evt => setUserData({ ...userData, confirm: evt.target.value })}
                                disabled={creatingUser || userData.password.length === 0}
                                // helperText={Constants.passwordRules}
                                onKeyPress={handleEnterToSubmit}
                                inputProps={{
                                    tabIndex: isMember ? 9 : 6
                                }}
                            />  
                            { /* onClick={() => setShowPasswordRules(!showPasswordRules)} */ }
                            <div style={{ display: 'flex', marginTop: '7px', paddingInlineStart: '5px', cursor: 'pointer' }}>
                                <div style={{ height:'18px' }}>
                                    <InfoIcon style={{ color: showPasswordError() ? 'red' : 'rgba(12, 38, 89, 0.38)', fontSize: '15px'  }} />
                                </div>
                                <div style={{ display: 'inline-block', color: showPasswordError() ? 'red' : 'rgba(12, 38, 89, 0.38)', fontSize: '0.75rem', marginLeft: '2px' }}>
                                    { /* {showPasswordRules ? 'Hide' : 'Show'} Password Rules */}
                                    Password Rules
                                </div>
                            </div>
                            {showPasswordRules && (
                                <ul style={{ color: 'rgba(12, 38, 89, 0.38)', fontSize: '0.75rem', paddingInlineStart: '25px', marginBottom: '0px', marginTop: '5px' }}>
                                    <li>Must be at least 8 characters</li>
                                    <li>Must include at least 1 uppercase letter</li>
                                    <li>Must include at least 1 symbol</li>
                                    <li>Allowed symbols: ! @ # % $ &amp;</li>
                                </ul>
                            )}
                        </Grid>     
                    </Grid>
                    {recaptchaComponent}
                    <ProgressButton
                        inProgressText='Registering'
                        stoppedText='Register'
                        showProgressWhen={creatingUser}
                        disabledWhen={creatingUser || !inputCompleteWhen()}
                        onClickHandler={handleRegisterStart}
                        tabIndex={isMember ? 10 : 7}
                    />
                    <Grid container justify="center">
                        <Grid item>
                            <Link to="/home" style={{ textDecoration: 'none', color: '#0C2659' }}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </ShowHidePasswordProvider>
            )}
            {registrationStep === RegistrationSteps.verificationEmailSent && (
                <InfoStep icon='email' title='Verification Email Sent' buttonText='To OptiMed Portal' onButtonClick={toHome}> 
                    <Typography variant="body1" style={{ textAlign: 'left' }} paragraph>
                        To complete your registration, we will need to verify your email address. We have sent you an email with a verification link.
                    </Typography>
                    <Typography variant="body1" style={{ textAlign: 'left', fontWeight: 'bold' }} paragraph>
                        Please be sure to check your Spam or Junk folder if you don't receive the email in your Inbox folder.
                    </Typography>
                    <Typography variant="body1" style={{ textAlign: 'left' }} paragraph>
                        Once you click on the link in the email, you will have access to the <Link to="/signin" style={{ textDecoration: 'none' }}>OptiMed Portal</Link>.
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'center' }}>
                        Need help?<br />Email us at: <MaterialLink href={`mailto:${Constants.supportEmail}`}>{Constants.supportEmail}</MaterialLink>
                    </Typography>
                </InfoStep>
            )}
            {registrationStep === RegistrationSteps.error && (
                <InfoStep icon='error' title={errorContent.title} buttonText='Back'
                    onButtonClick={evt => setRegistrationStep(RegistrationSteps.collectUserInfo)}> 
                    <Typography variant="body1" style={{ textAlign: 'left' }} paragraph>
                        {errorContent.description}
                    </Typography>
                </InfoStep>
            )}
            {registrationStep === RegistrationSteps.onComplete && (
                <InfoStep icon='complete' title='Registration Complete' buttonText={'To OptiMed Portal'} onButtonClick={toHomeOnComplete}> 
                    <Typography variant="body1" style={{ textAlign: 'left' }} paragraph> 
                        You are now authorized to access the OptiMed Portal.
                    </Typography>
                </InfoStep>
            )}
            {registrationStep === RegistrationSteps.needsGroupAssignment && (
                <InfoStep icon='assignment' title='Pending Group Assignment' buttonText='To OptiMed Portal' onButtonClick={toHome}> 
                    <Typography variant="body1" style={{ textAlign: 'left' }} paragraph> 
                        We are working to get your new account assigned to the correct group(s). Once this process is complete, you will receive an email.
                    </Typography>
                </InfoStep>
            )}
            {registrationStep === RegistrationSteps.locked && (
                <InfoStep icon='lock' title='Account Locked' buttonText='To OptiMed Portal' onButtonClick={toHome}> 
                    <Typography variant="body1" style={{ textAlign: 'left' }} paragraph>
                        Your account is currently locked. To get your account unlocked, please contact us at:
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'center' }}>
                        <MaterialLink href={`mailto:${Constants.supportEmail}`}>{Constants.supportEmail}</MaterialLink>
                    </Typography>
                </InfoStep>
            )}
            {registrationStep === RegistrationSteps.pendingAccountSetup && (
                <InfoStep icon='lock' title='Pending Account Setup' buttonText='To OptiMed Portal' onButtonClick={toHome}> 
                    <Typography variant="body1" style={{ textAlign: 'left' }} paragraph>
                        We are working to get your new account setup. Once this process is complete, a member of our Sales Support team will contact you.
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: 'center' }}>
                        <MaterialLink href={`mailto:${Constants.supportEmail}`}>{Constants.supportEmail}</MaterialLink>
                    </Typography>
                </InfoStep>
            )}
        </>
    )
}

export default Register