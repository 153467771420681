import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const Copyright = () => {

    return (
        <>
        <Typography variant="body2" color="textSecondary" align="center" display="block" gutterBottom>
            {`Copyright © ${new Date().getFullYear()} `}
            <Link color="inherit" href="https://www.optimedhealth.com/">
                OptiMedHealth Plans
            </Link>
        </Typography>
        <Typography variant="caption" color="textSecondary" align="center" display="block" style={{ marginBottom: '20px' }}>
            <Link color="inherit" href="https://www.optimedhealth.com/privacy-policy/">Privacy Policy</Link> - Terms of Service
        </Typography>
        </>
    )
}

export default Copyright