import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import CheckIcon from '@material-ui/icons/CheckOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import LockIcon from '@material-ui/icons/LockOutlined'
import WrenchIcon from '@material-ui/icons/BuildOutlined'
import AlarmIcon from '@material-ui/icons/AlarmOutlined'
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined'
import { useSharedStyles } from '../utils/SharedStyle'

const InfoStep = (props) => {

    const theme = useTheme()
    const classes = useSharedStyles()
    const { icon, title, buttonText, onButtonClick } = props

    return (
        <>
            <Avatar className={classes.avatar}>
                {icon === 'lock' && <LockIcon />}
                {icon === 'email' && <EmailIcon />}
                {icon === 'error' && <ErrorIcon />}
                {icon === 'down' && <WrenchIcon />}
                {icon === 'assignment' && <AssignmentIcon />}
                {icon === 'complete' && <CheckIcon />}
                {icon === 'alarm' && <AlarmIcon />}
            </Avatar>
            <Grid container spacing={2} style={{ marginTop: theme.spacing(1) }}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: theme.spacing(2) }}>
                    {props.children}
                </Grid>
            </Grid>
            {buttonText &&
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={onButtonClick}
                >
                    {buttonText}
                </Button>
            }
        </>
    )
}

export default InfoStep