import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { ShowHidePasswordTextField, ShowHidePasswordProvider } from './ShowHidePassword'
import Utils from '../utils/Utils'
import Constants from '../Constants'
import ProgressButton from './ProgressButton'
import InfoStep from './InfoStep'
import { sendPasswordResetEmail, verifyPasswordResetCode, resetPassword } from '../services/Backend' 
import _ from 'lodash'

const ForgotPasswordSteps = {

    collectUserInfo: 0,
    resetEmailSent: 1,
    resetPassword: 2,
    passwordUpdated: 3,
    error: 4,
    requestExpired: 5
}

const ForgotPassword = ({ location, history }) => {

    const theme = useTheme()

    const [forgotPasswordStep, setForgotPasswordStep] = useState(ForgotPasswordSteps.collectUserInfo)
    const [email, setEmail] = useState(Utils.isValidEmail(_.isUndefined(location.state) ? '' : location.state.email || '') ? location.state.email : '')
    const [passwordResetData, setPasswordResetData] = useState({
        code: '',
        password: '', 
        confirm: ''
    })
    const [sendingEmail, setSendingEmail] = useState(false)
    const [resettingPassword, setResettingPassword] = useState(false)
    const [emailAttempted, setEmailAttempted] = useState(false)

    useEffect(() => {
    
        const params = Utils.getQueryParams()
        const step = params['step'] || ForgotPasswordSteps.collectUserInfo

        if (step === `${ForgotPasswordSteps.resetPassword}`) {

            const oobCode = params['oobCode']

            verifyPasswordResetCode(oobCode)
                .then(resp => {

                    if (resp.isError) {

                        setForgotPasswordStep(ForgotPasswordSteps.requestExpired)

                        Utils.timedRedirect('/internal-signin', history, 10)

                    } else {

                        setEmail(resp.email)
                        setPasswordResetData(passwordResetData => { return { ...passwordResetData, code: oobCode } })
                        
                        setForgotPasswordStep(Number(step))
                    }
                })
            
        } else {

            setForgotPasswordStep(Number(step))
        }
        
    }, [history])

    useEffect(() => {
        
        if (sendingEmail) {

            console.log('Sending email ...')

            sendPasswordResetEmail(email).then(resp => {
                
                if (resp.isError) {

                    /* Not much worth showing the user here or we can really do anything about so just show the error screen */

                    setForgotPasswordStep(ForgotPasswordSteps.error)

                } else {

                    setForgotPasswordStep(ForgotPasswordSteps.resetEmailSent)
                }

                Utils.timedRedirect('/internal-signin', history, 10)

                setSendingEmail(false)
            })
        }

    }, [sendingEmail, email, history])

    useEffect(() => {
        
        if (resettingPassword) {

            console.log('Resetting password ...')

            resetPassword(passwordResetData.code, passwordResetData.password).then(resp => {
                
                if (resp.isError) {

                    /* Not much worth showing the user here or we can really do anything about so just show the error screen */

                    setForgotPasswordStep(ForgotPasswordSteps.error)

                } else {

                    setForgotPasswordStep(ForgotPasswordSteps.passwordUpdated)
                }

                Utils.timedRedirect('/internal-signin', history, 10)

                setResettingPassword(false)
            })
        }

    }, [resettingPassword, passwordResetData, history])

    const toHome = (evt) => history.replace('/internal-signin')

    const showPasswordError = () => passwordResetData.password.length > 0 && !Utils.isValidPassword(passwordResetData.password,
        Constants.allowedSpecialChars, Constants.validPasswordRegex)
    
    const showConfirmError = () => !showPasswordError() && passwordResetData.password !== passwordResetData.confirm

    const enableResetWhen = () => passwordResetData.password.length > 0 && passwordResetData.confirm.length > 0 && 
        !showConfirmError()

    return (
        <>
            {forgotPasswordStep === ForgotPasswordSteps.collectUserInfo && (
                <ShowHidePasswordProvider>
                    <Grid container spacing={2} style={{ marginTop: theme.spacing(2) }}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                We will send you an email with instructions for resetting your password. 
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: theme.spacing(1) }}>
                            <TextField
                                autoFocus={true}
                                variant="outlined"
                                required
                                fullWidth
                                value={email}
                                onBlur={evt => setEmailAttempted(true)}
                                onChange={evt => { setEmail(evt.target.value.trim()) }}
                                disabled={sendingEmail}
                                id="email"
                                label="Email Address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                error={emailAttempted && !Utils.isValidEmail(email)}
                                inputProps={{
                                    tabIndex: 1
                                }}
                            />
                        </Grid>
                    </Grid>
                    <ProgressButton
                        inProgressText='Sending Email'
                        stoppedText='Send Email'
                        showProgressWhen={sendingEmail}
                        disabledWhen={sendingEmail || !Utils.isValidEmail(email)}
                        onClickHandler={evt => setSendingEmail(true)}
                        tabIndex={2}
                    />
                </ShowHidePasswordProvider>
            )}
            {forgotPasswordStep === ForgotPasswordSteps.resetEmailSent && (
                <InfoStep icon='email' title='Reset Email Sent' buttonText='To OptiMed Portal' onButtonClick={toHome}> 
                    <Typography variant="body1" style={{ textAlign: 'left' }} paragraph>
                        We have sent you an email with instructions for resetting your password.
                    </Typography>
                </InfoStep>
             )}
            {forgotPasswordStep === ForgotPasswordSteps.resetPassword && (
                <>
                    <ShowHidePasswordProvider>
                        <Grid container spacing={2} style={{ marginTop: theme.spacing(2) }}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Enter new password for: 
                                </Typography>
                                <Typography variant="subtitle1" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                    {email}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: theme.spacing(2) }}>
                                <ShowHidePasswordTextField
                                    autoFocus
                                    id="password"
                                    name="password"
                                    label="Password"
                                    error={showPasswordError()}
                                    value={passwordResetData.password}    
                                    onChange={evt => setPasswordResetData({ ...passwordResetData, password: evt.target.value })} 
                                    disabled={resettingPassword}
                                    showIcon
                                    inputProps={{
                                        tabIndex: 1
                                    }}
                                />     
                            </Grid>   
                            <Grid item xs={12}>
                                <ShowHidePasswordTextField
                                    id="confirm"
                                    name="confirm"
                                    label="Confirm Password"
                                    error={showConfirmError()}
                                    value={passwordResetData.confirm}
                                    onChange={evt => setPasswordResetData({ ...passwordResetData, confirm: evt.target.value })}
                                    disabled={resettingPassword || passwordResetData.password.length === 0}
                                    helperText={Constants.passwordRules}
                                    inputProps={{
                                        tabIndex: 2
                                    }}
                                />  
                            </Grid> 
                            <ul style={{ color: 'rgba(12, 38, 89, 0.38)', fontSize: '0.75rem', paddingInlineStart: '25px', marginBottom: '0px', marginTop: '5px' }}>
                                <li>Must be at least 8 characters</li>
                                <li>Must include at least 1 uppercase letter</li>
                                <li>Must include at least 1 symbol</li>
                                <li>Allowed symbols: ! @ # % $ &amp;</li>
                            </ul>
                        </Grid>
                        <ProgressButton
                            inProgressText='Saving' 
                            stoppedText='Save'
                            showProgressWhen={resettingPassword}
                            disabledWhen={resettingPassword || !enableResetWhen()}
                            onClickHandler={evt => setResettingPassword(true)}
                            tabIndex={3}
                        />
                    </ShowHidePasswordProvider>
                </>)}
            {forgotPasswordStep === ForgotPasswordSteps.requestExpired && (
                <InfoStep icon='alarm' title='Reset Request Expired' buttonText='To OptiMed Portal' onButtonClick={toHome}> 
                    <Typography variant="body1" style={{ textAlign: 'left' }} paragraph>
                        This reset request has expired. You can start another if you still need to reset your password. 
                    </Typography>
                </InfoStep>
            )}
            {forgotPasswordStep === ForgotPasswordSteps.error && (
                <InfoStep icon='error' title='Unexpected Error' buttonText='To OptiMed Portal' onButtonClick={toHome}> 
                    <Typography variant="body1" style={{ textAlign: 'left' }} paragraph>
                        An unexpected error has occured while trying handle your request. Please try again later.
                    </Typography>
                </InfoStep>
            )}
            {forgotPasswordStep === ForgotPasswordSteps.passwordUpdated && (
                <InfoStep icon='complete' title='Password Reset' buttonText='To OptiMed Portal' onButtonClick={toHome}> 
                    <Typography variant="body1" style={{ textAlign: 'left' }} paragraph>
                        Your password has been successfully reset. You can either click the button below or wait a 10 seconds to be forwarded automatically.
                    </Typography>
                </InfoStep>
             )}
        </>
    )
}

export default ForgotPassword