import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Register from "./components/Register";
import SignIn from "./components/SignIn";
import ForgotPassword from "./components/ForgotPassword";
import Copyright from "./components/Copyright";
import Logo from "./images/OptiMed_Logo.png";
import SquaresPNG from "./images/OptiMed_Background.png";
import { useSharedStyles } from "./utils/SharedStyle";
import InfoStep from "./components/InfoStep";
import Utils from "./utils/Utils";
import _ from "lodash";

export default function App() {
  const isTesting = _.has(Utils.getQueryParams(), "testing");
  const isDown = false;

  return (
    <Router>
      <Container maxWidth="sm">
        <Container
          component="main"
          maxWidth="xs"
          style={{ paddingLeft: "24px", paddingRight: "24px" }}
        >
          <CssBaseline />
          <div className={useSharedStyles().paper}>
            <img
              src={Logo}
              alt="OptiMed"
              style={{ width: "100%", height: "100%" }}
            ></img>
            {isDown && !isTesting && (
              <InfoStep icon="down" title="Upgrade In Progress">
                <Typography
                  variant="body1"
                  style={{ textAlign: "center" }}
                  paragraph
                >
                  Sorry for the inconvenience and we'll be back online as soon
                  as possible.
                </Typography>
              </InfoStep>
            )}
            {(!isDown || isTesting) && (
              <Switch>
                <Route
                  path="/employee-signin"
                  exact
                  render={(props) => <SignIn {...props} isEmployee={true} />}
                />
                <Route
                  path="/signin"
                  exact
                  render={(props) => <SignIn {...props} />}
                />
                <Route
                  path="/internal-signin"
                  exact
                  render={(props) => (
                    <SignIn {...props} skipSigninCheck={true} />
                  )}
                />
                <Route
                  path="/register"
                  exact
                  render={(props) => <Register {...props} inRole="hr-rep" />}
                />
                <Route
                  path="/registerMember"
                  exact
                  render={(props) => <Register {...props} inRole="member" />}
                />
                <Route
                  path="/registerFlorida"
                  exact
                  render={(props) => <Register {...props} inRole="hr-rep" />}
                />
                <Route
                  path="/registerBroker"
                  exact
                  render={(props) => <Register {...props} inRole="broker" />}
                />
                <Route
                  path="/registerOnlineRatingBroker"
                  exact
                  render={(props) => <Register {...props} inRole="broker" />}
                />
                <Route
                  path="/forgotPassword"
                  exact
                  component={ForgotPassword}
                />
                <Route component={SignIn} />
              </Switch>
            )}
            <img
              src={SquaresPNG}
              alt="OptiMed"
              style={{ width: "90%", height: "90%", marginTop: "24px" }}
            ></img>
          </div>
        </Container>
      </Container>
      <Copyright />
    </Router>
  );
}
