import _ from 'lodash'

const getQueryParams = () => {

    return window.location.search
        .substr(1)
        .split('&')
        .reduce(function (q, query) {

        const chunks = query.split('=');
        const key = chunks[0];
        const value = chunks[1];

        return (q[key] = value, q);

        }, {})
}

const isValidEmail = (email) => {

    const parts = email.split("@")

    if (parts.length > 0 && _.head(_.head(parts), 1) !== "." && _.last(_.head(parts)) !== ".") {

        const regex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/)

        return regex.test(email)
        
    } else {

        return false
    }
}

const isValidPassword = (password, allowedSpecialChars, validPasswordRegex) => {

    const oneSpecial = _.filter(password.split(''), v => _.findIndex(allowedSpecialChars.split(' '), spec => spec === v) > -1).length > 0
    const oneUppercase = password.toLowerCase() !== password

    return oneSpecial && oneUppercase && validPasswordRegex.test(password)
}

const isCompleteData = (data) => {

    return _.filter(_.values(data), v => (v || '').trim().length > 0).length === _.keys(data).length
}

const timedRedirect = (to, history, delaySeconds = 5) => {

    return new Promise(resolve => {
        setTimeout(() => {

            if (history) {

                history.replace(to)

            } else {

                window.location.replace(to)
            }

            resolve()
            
        }, (delaySeconds * 1000))
    })
}

const Utils = {

    getQueryParams,
    isValidEmail,
    isValidPassword,
    isCompleteData,
    timedRedirect
}

export default Utils