import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import axios from 'axios'
import _ from 'lodash'
import * as Msal from 'msal'
import { RegistrationSteps } from '../Constants'

const isDev = (process.env.NODE_ENV === "development" && _.isUndefined(process.env.REACT_APP_USE_FIREBASE))

const msalInstance = new Msal.UserAgentApplication({
    auth: {
        clientId: '5602c3b9-9a63-4da3-9e0e-a8af211d6bd4',
        authority: "https://login.microsoftonline.com/b7fcb013-1d97-43b0-8136-4bd00f05ea66"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
})

if (!firebase.apps.length) {

    if (!isDev) {

        firebase.initializeApp({ apiKey: 'AIzaSyDfDnc70TlTFyEuDRj-kxlI970acaB_wv0', databaseURL: 'https://automated-atlas-225914.firebaseio.com', projectId: 'automated-atlas-225914' })
    }
}

export const ErrorCodes = {

    recaptchaRefreshNeeded: -40001
}

export const createUser = (email, password, roles) => {

    return isDev ? Promise.resolve({ data: { isError: false } }) :

        new Promise(resolve => {

            /*
            firebase.auth().createUserWithEmailAndPassword(email, password)
                .then(userCreds => {

                    return axios({
                            url: '/roleRequest',
                            method: 'POST',
                            data: { userId: userCreds.user.uid, roles},
                            responseType: 'json'
                        })
                        .then(resp => resolve({ isError: false }), error => { throw error })
                })
                .catch(error => {

                    console.error(error)

                    resolve({ isError: true, isDupUser: error.code === 'auth/email-already-in-use' })
                })
            */
            
           axios({
                url: '/createUser',
                method: 'POST',
                data: { email, password, roles},
                responseType: 'json'
            })
            .then(response => {
                
                const resp = response.data    
                
                resolve({
                    isError: resp.isError,
                    isDupUser: _.has(resp, "isDupUser") ? resp.isDupUser : false,
                    isMemberNotFound: _.has(resp, "isMemberNotFound") ? resp.isMemberNotFound : false
                })      
            })
            .catch(error => {

                console.error(error)

                resolve({ isError: true, isDupUser: false, isMemberNotFound: false })
            })
        })
}

const signInDevHandler = () => {

    return new Promise(resolve => {

        // Return whatever response is needed to be test:

        // Broker response: resolve({ isError: false, to: '/iq' })
        // HR Rep response: resolve({ isError: false, to: `/portal?ott=12345` })

        // Or error condition:

        // resolve({ isError: true, step: RegistrationSteps.needsGroupAssignment})

        resolve({ isError: false, to: '/iq' })
    })
}

export const signIn = (email, password) => {

    const signInFailed = { isError: true }

    return isDev ? signInDevHandler() :

        new Promise(resolve => {

            firebase.auth().signInWithEmailAndPassword(email, password)
                .then(userCreds => {

                    whenSignedInSuccessfully(userCreds.user)
                        .then(resp => resolve(resp))
                        .catch(error => resolve(signInFailed))
                })
                .catch(error => {

                    /* Can't do much - or really want to so just return isError: true */

                    if (error.code === 'auth/user-disabled') {

                        resolve({ isError: true, isDisabled: true })

                    } else {

                        resolve(signInFailed)
                    }
                })
        })
}

export const employeeSignIn = () => {

    const signInFailed = { isError: true }
    const requestObj = { scopes: ["user.read"] }

    const getUserInfo = () => {

        return new Promise((resolve, reject) => {

            const graphCall = (token) => {

                const xmlHttp = new XMLHttpRequest()

                xmlHttp.onreadystatechange = function () {

                    if (this.readyState === 4 && this.status === 200) {
                        resolve({ ...JSON.parse(this.responseText), msToken: token })
                    }
                }

                xmlHttp.open('GET', 'https://graph.microsoft.com/v1.0/me', true)
                xmlHttp.setRequestHeader('Authorization', 'Bearer ' + token)
                xmlHttp.send()
            }

            msalInstance.acquireTokenSilent(requestObj)
                .then(tokenResponse => graphCall(tokenResponse.accessToken))
                .catch(error => {

                    const requiresInteraction = (errorCode) => {

                        if (!errorCode || !errorCode.length) {
                            return false;
                        }

                        return errorCode === "consent_required" ||
                            errorCode === "interaction_required" ||
                            errorCode === "login_required";
                    }

                    if (requiresInteraction(error.errorCode)) {

                        msalInstance.acquireTokenPopup(requestObj)
                            .then(tokenResponse => graphCall(tokenResponse.accessToken))
                            .catch(error => reject())

                    } else {

                        reject()
                    }
                })
        })
    }

    const checkIfSignedIn = () => {

        return new Promise(resolve => {

            if (_.isNull(msalInstance.getAccount())) {

                resolve({ isError: true })

            } else {

                resolve({ isError: false })
            }
        })
    }

    return isDev ? signInDevHandler() :

        new Promise(resolve => {

            checkIfSignedIn()
                .then(resp => {

                    if (resp.isError) {

                        return msalInstance.loginPopup(requestObj)
                            .then(() => getUserInfo())

                    } else {

                        return getUserInfo()
                    }
                })
                .then(data => {

                    return axios.post('/fetchEmployee', data)
                        .then(resp => {

                            return firebase.auth().signInWithCustomToken(resp.data.token)
                                .then(userCreds => {

                                    return whenSignedInSuccessfully(userCreds.user)
                                        .then(resp => resolve(resp))
                                })
                        })
                        .catch(error => {

                            console.error(error)

                            resolve(signInFailed)
                        })

                }).catch(error => {

                    const popupErrorContent = 'error opening popup window'

                    resolve({ isError: true, needsToAcceptPopups: _.toLower(error).indexOf(popupErrorContent) > -1 })
                })
        })
}

export const sendPasswordResetEmail = (email) => {

    return isDev ? Promise.resolve({ isError: false }) :

        new Promise(resolve => {

            firebase.auth().sendPasswordResetEmail(email)
                .then(() => resolve({ isError: false }))
                .catch(error => {

                    /* No good reason to give the user any help here so just return bad */

                    resolve({ isError: true })
                })
            })
}

export const verifyPasswordResetCode = (code) => {

    return isDev ? Promise.resolve({ email: 'jdoe@gmail.com', isError: false }) :

        new Promise(resolve => {

            console.log('Calling verifyPasswordResetCode ...')

            firebase.auth().verifyPasswordResetCode(code)
                .then(emailToReset => {

                    console.log(`Password reset for ${emailToReset}`)

                    resolve({ email: emailToReset, isError: false })
                })
                .catch(error => {

                    resolve({ isError: true })
                })
            })
}

export const resetPassword = (code, password) => {

    return isDev ? Promise.resolve({ isError: false }) :

        new Promise(resolve => {

            firebase.auth().confirmPasswordReset(code, password)
                .then(() => {
                    resolve({ isError: false })
                })
                .catch(error => {

                    resolve({ isError: true })
                })
        })
}

const whenSignedInSuccessfully = (fbUser) => {

    return new Promise(resolve => {

        if (fbUser.emailVerified) {

            const db = firebase.firestore()

            return db.collection("users").doc(fbUser.uid)
                .get()
                .then(userDoc => {

                    if (userDoc.exists) {

                        const user = userDoc.data()

                        const isMember = _.has(user.roles || {}, 'member')
                        const isBroker = _.has(user.roles || {}, 'broker') || _.has(user.pendingRoles || {}, 'broker')
                        const isEmployee = _.has(user.roles || {}, 'employee')
                        const gotoOnlineRating = _.has(user.roles || {}, 'rating')

                        if (isBroker) {

                            /*
                                3 ways in for broker:

                                    1. Quick Quote Only - broker, iq (all in pending)
                                    2. Portal - broker (pending or roles)
                                    3. Rating Access - broker, rating (all in pending or all in roles)
                            */

                            const isApprovedBroker = _.has(user.roles || {}, 'broker')

                            if (isApprovedBroker) {

                                /* For now, all approved brokers go to the portal - but if that needed to change, rating brokers would also have gotoOnlineRating set */

                                //if (gotoOnlineRating) {

                                //    resolve({ isError: false, to: '/rating' })

                                //} else {

                                    resolve({ isError: false, to: '/portal' })
                                //}

                            } else {

                                /* First, test for Quick Quote only user since they will always directly to quick quote - pending, extactly 2 roles */

                                const isQuickQuoteBroker = _.keys(user.pendingRoles).length === 2 && _.has(user.pendingRoles, 'iq')

                                if (isQuickQuoteBroker) {

                                    resolve({ isError: false, to: '/iq' })

                                } else {

                                    /* Since we know the broker is portal or rating but not approved, then show then the pending screen */

                                    resolve({ isError: true, step: RegistrationSteps.pendingAccountSetup})
                                }
                            }

                        } else if (isEmployee) {

                            if (gotoOnlineRating) {

                                resolve({ isError: false, to: '/rating' })

                            } else {

                                resolve({ isError: false, to: '/portal' })
                            }

                        } else {

                            /* NOTE: The use of the token can go away once invoices are in Firebase */

                            return fbUser.getIdTokenResult(true)
                                .then(idTokenResult => {

                                    if ((idTokenResult.claims.groupNumber || idTokenResult.claims.taxId) || isMember) {
                                        
                                        resolve({ isError: false, to: `/portal` })

                                    } else {

                                        resolve({ isError: true, step: RegistrationSteps.needsGroupAssignment})
                                    }
                                })
                                .catch (error => { throw error })
                        }

                    } else {

                        /*
                         NOTE: This would be bad but the only time this could happen is the extreme edge case between a user being
                               and the user document being added to the collection. For now we're going to assume thats the
                               case and just show the email verification seen - which has the support email
                        */

                        resolve({ isError: true, step: RegistrationSteps.verificationEmailSent})
                    }
                })
                .catch(error => { throw error })

        } else {

            resolve({ isError: true, step: RegistrationSteps.verificationEmailSent})
        }
    })
}

export const checkForSignedInUser = () => {

    const notSignedInResp = { isError: true }

    return isDev ? signInDevHandler() :

        new Promise(resolve => {

            firebase.auth().onAuthStateChanged(user => {

                if (user) {

                    whenSignedInSuccessfully(user)
                        .then(resp => resolve(resp))
                        .catch(error => resolve(notSignedInResp))

                } else {

                    resolve(notSignedInResp)
                }
            })
        })
}
