import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { ShowHidePasswordTextField, ShowHidePasswordProvider } from './ShowHidePassword'
import ProgressButton from './ProgressButton'
import Utils from '../utils/Utils'
import { signIn, employeeSignIn, checkForSignedInUser } from '../services/Backend'
import useRecaptcha from '../hooks/Recaptcha'
import Constants from '../Constants'
import _ from 'lodash'

const handleSignInResponse = (history, resp, onInvalid) => {
  
    if (resp.isError) {

        /* If we got an error but were told to go somewhere else */
    
        if (resp.step) {

            history.push(`/register?step=${resp.step}`)

        } else {

            onInvalid(resp.isDisabled || false)
        }

    } else {

        console.log(`Redirecting to: [${resp.to}]`)

        if (process.env.NODE_ENV !== "development") {

            Utils.timedRedirect(resp.to, undefined, 0)
        }
    }
}

const SignIn = ({ history, skipSigninCheck, isEmployee }) => {

    const theme = useTheme()

    const [userData, setUserData] = useState({
        email: '',
        password: '',
        siginFailed: false,
        message: undefined
    })
    const [signingIn, setSigningIn] = useState(true)
    const [emailAttempted, setEmailAttempted] = useState(false)
    const [loginMessage, setLoginMessage] = useState('... Checking Account Status ...')

    const handleSignin = (rcResp) => {

        (rcResp.isError ? Promise.resolve(rcResp) : signIn(userData.email, userData.password))
            .then(resp => {

                handleSignInResponse(history, resp, (isDisabled) => setUserData({
                    email: userData.email,
                    password: '',
                    siginFailed: true,
                    message: isDisabled ? 'Account is currently disabled' : `Email and password entered don't match`
                }))

                return 
            })
            .then(() => setSigningIn(false))
    }

    const [startRecaptchaTransaction, recaptchaComponent] = useRecaptcha(Constants.recaptchaClientKey, handleSignin)

    useEffect(() => {

        // console.log('Running useEffect')

        if (isEmployee) {

            employeeSignIn()
                .then(resp => {

                    if (resp.isError) {

                        if (resp.needsToAcceptPopups) {

                            setLoginMessage('Accept pop-ups from this site and refresh')

                        } else {

                            setLoginMessage('Unable to log you in at this time or your account is still being setup')
                        }

                    } else {

                        return handleSignInResponse(history, resp, () => {
                    
                            setSigningIn(false)
    
                            return
                        })
                    }
                })

        } else {

            /* This will take the user directly to their target - if they are still signed in */
        
            if (!skipSigninCheck) {

                checkForSignedInUser()
                    .then(resp => handleSignInResponse(history, resp, () => {
                    
                        setSigningIn(false)

                        return
                    }))
            
            } else {

                setSigningIn(false)
            }
        }

    }, [history, skipSigninCheck, isEmployee])

    const inputCompleteWhen = () =>
        
        Utils.isCompleteData(_.omit(userData, ['siginFailed', 'message'])) && Utils.isValidEmail(userData.email)

    const focusToPasswordWhenInvalid = input => {

        /*
          The following was an attempt to put the user back in the password input on a failure. Unfortunitely, 
          it as bad side effect once the event occurs and would require the siginFailed flag to be reset - 
          but that currently clears the message
         */
        
        /*
        if (userData.siginFailed && input) {

            setTimeout(() => {
                
                input.focus()

            }, 100)
        }
        */
    }

    const handleSignInStart = (evt) => {

        startRecaptchaTransaction()
        setSigningIn(true)
    }

    const handleEnterToSubmit = (evt) => {

        if (evt.key === "Enter" && inputCompleteWhen()) {

            handleSignInStart()
        }
    }

    return (
        <>
            {(signingIn && !inputCompleteWhen()) ?
                /* Not sure if this is a good idea yet, but there could be a lag so wouldn't want the user to be clueless */
                <div style={{ marginTop: theme.spacing(2) }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        {loginMessage}
                    </Typography>
                    <LinearProgress />
                </div>
                :
                <ShowHidePasswordProvider>
                    <Grid container spacing={2} style={{ marginTop: theme.spacing(2) }} justify="center">
                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                label="Email Address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                autoFocus
                                variant="outlined"
                                required
                                fullWidth
                                onBlur={evt => setEmailAttempted(true)}
                                value={userData.email}
                                onChange={evt => setUserData({ ...userData, email: evt.target.value.trim() })}
                                disabled={signingIn}
                                error={emailAttempted && !Utils.isValidEmail(userData.email)}
                                inputProps={{
                                    tabIndex: 1
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ShowHidePasswordTextField
                                id="password"
                                name="password"
                                label="Password"
                                inputRef={focusToPasswordWhenInvalid}
                                value={userData.password}
                                onChange={evt => setUserData({ ...userData, password: evt.target.value })}
                                disabled={signingIn}
                                showIcon
                                error={userData.siginFailed}
                                helperText={userData.siginFailed ? userData.message : ''}
                                onKeyPress={handleEnterToSubmit}
                                inputProps={{
                                    tabIndex: 2
                                }}
                            />
                        </Grid>
                    </Grid>
                    {recaptchaComponent}
                    <ProgressButton
                        inProgressText='Signing In'
                        stoppedText='Sign In'
                        showProgressWhen={signingIn}
                        disabledWhen={signingIn || !inputCompleteWhen()}
                        onClickHandler={handleSignInStart}
                        tabIndex={3}
                    />
                    <Grid container justify="center">
                        <Grid item>
                            <Link
                                to={{
                                    pathname: `/forgotPassword`,
                                    state: {
                                        email: userData.email
                                    }
                                }}
                                style={{ textDecoration: 'none', color: '#0C2659'}}>Trouble signing in?</Link>
                        </Grid>
                    </Grid>
                </ShowHidePasswordProvider>
            }
        </>
    )
}

export default SignIn