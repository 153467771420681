import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSharedStyles } from '../utils/SharedStyle'

const ProgressButton = ({ showProgressWhen, inProgressText, stoppedText, disabledWhen, onClickHandler, tabIndex }) => {

    const classes = useSharedStyles()

    return (
        <div className={classes.buttonWrapper}>
            <Button
                tabIndex={tabIndex}
                onClick={onClickHandler}
                fullWidth
                disabled={disabledWhen}
                variant="contained"
                color="primary"  
                className={classes.submit}
            >
                {showProgressWhen ? `... ${inProgressText} ...` : stoppedText}
            </Button>
            {showProgressWhen && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    )
}

export default ProgressButton