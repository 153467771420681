const allowedSpecialChars = `! @ # % $ &`
const validPasswordRegex = /^[a-zA-z0-9!@#%$&]{8,}$/

const Constants = {

    recaptchaClientKey: '6LeRGrkUAAAAALHfx1BmD4QHs912R6_BWR8do6GX',
    supportEmail: 'support@optimedhealth.com',
    passwordRules: `Min 8 len, 1 uppercase letter, and least 1: ${allowedSpecialChars}`,
    allowedSpecialChars,
    validPasswordRegex
}

export const RegistrationSteps = {

    collectUserInfo: 0,
    verificationEmailSent: 1,
    error: 2,
    onComplete: 3,
    needsGroupAssignment: 4,
    locked: 5,
    pendingAccountSetup: 6
}

export default Constants